<template>
    <modal class="invoiceModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">会员充值开票</div>
        <div class="modal-body">
            <div class="flex-box" ref="rightInput">
                <div class="left-box">
                    <div class="title">会员信息</div>
                    <div class="tr-li">
                        <div class="td-3">
                            <div class="lable-txt">卡号：</div>
                            <div class="from-input">{{memberInfo?.Card_No}}</div>
                        </div>
                        <div class="td-2">
                            <div class="lable-txt">客户姓名：</div>
                            <div class="from-input">{{memberInfo?.MemberName}}</div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-3">
                            <div class="lable-txt">手机号：</div>
                            <div class="from-input">{{memberInfo?.Mobile}}</div>
                        </div>
                        <div class="td-2">
                            <div class="lable-txt">会员等级：</div>
                            <div class="from-input">{{memberInfo?.Bestech_Card_KindName}}</div>
                        </div>
                    </div>
                    <div class="title">开票信息</div>
                    <div class="tr-li td-box">
                        <div class="lable-txt">剩余可开票金额：</div>
                        <div class="from-input"><input class="money"  type="text" :value="memberInfo?.InvoiceMoney_Remain" readonly/></div>
                    </div>
                    <div class="tr-li td-box">
                        <div class="lable-txt">本次开票金额：</div>
                        <div class="from-input"><input-pattern ref="invoiceMoney" class="money key-focus" type="text" pattern="number" v-model="invoiceMoney" /></div>
                    </div>
                    <div class="tr-li td-box">
                        <div class="lable-txt">发票号码：</div>
                        <div class="from-input"><input class="key-focus" type="text" v-model="invoiceNo"/></div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="remarks-box">
                <div class="lable-txt">开票备注：</div>
                <div class="from-input"><textarea class="key-focus" placeholder="100字以内" v-model="remark" maxlength="100"/></div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn selected" @click="recordShow=true">查看开票记录</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <modal-load :isShow="recordShow" isUnload>
            <invoice-record-model :isShow="recordShow" :memberInfo="memberInfo" @refresh="refresh" @close="recordShow=false"/>
        </modal-load>
    </modal>
</template>

<script>
import invoiceRecordModel from './invoiceRecordModel/invoiceRecordModel.vue';
import {initKeyBoard} from '/src/common';

/**去开票界面如下  */
export default {
  components: { invoiceRecordModel },
    name:"InvoiceModel",
    emits:["close","confirm","refresh"],
    props:{ 
        /**是否显示弹层 */
        isShow:{
            type:Boolean,
            default:false
        },
        /** 传入 会员信息 */
        memberInfo:{
            type:[Object,String],
            default:null
        }
    },
    data(){
        return{
            //开票记录弹层
            recordShow:false,
            invoiceMoney:0,
            //发票号
            invoiceNo:'',
            //备注
            remark:""
        }
    },
    mounted(){
        this.$nextTick(()=>{
            initKeyBoard.call(this,this.$refs.rightInput);
            this.$refs.invoiceMoney.focus();
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            this.invoiceMoney=0;
            //发票号
            this.invoiceNo='';
            //备注
            this.remark="";
        },
        /**关闭 */
        hide(){
            this.$emit("close");
        },
        /**点击确认 */
        confirm(){
            if(this.memberInfo.InvoiceMoney_Remain==0){
                this.$message.warning("没有可开票金额!");
                return;
            }
            console.log(this.invoiceMoney)
            if(!(this.invoiceMoney>0)){
                this.$message.warning("本次开票金额必须大于0!");
                this.$refs.invoiceMoney.focus();
                return;
            }
            if(Number(this.memberInfo.InvoiceMoney_Remain)<Number(this.invoiceMoney)){
                this.$message.warning("本次开票金额不能大于剩余可开票金额");
                this.$refs.invoiceMoney.focus();
                return;
            }
            this.userInfo=this.$auth.getUserInfo();
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,//操作员
                Operator_Pos:this.userInfo?.Site_Name, //操作站点
                Card_AutoID:this.memberInfo.Bestech_Card_AutoID,
                Card_No:this.memberInfo.Card_No,
                Invoice_Money:this.invoiceMoney,
                Invoice_No:this.invoiceNo,
                Remark:this.remark
            }
            this.$confirm("您确认本次开"+param.Invoice_Money+"元发票吗？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText:"取消",
				callback: (name) => {
					if(name=='confirm'){
						this.RechargeInvoice(param);
					}
				},
			});
        },
        //开票
        RechargeInvoice(param){
            const loading = this.$loading({
                text: "开票请求中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.IssueMemberRechargeInvoice",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    this.init();
                    this.$emit("confirm");
                    this.$message.success("开票成功");
                }else{
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('开票失败：'+e);
                console.log('开票失败：',e);
            })
        },
        /**刷新 会员信息 */
        refresh(){
            this.$emit("refresh");
        }
    }
}
</script>

<style lang="scss">
@import "./invoiceModel.scss";
</style>